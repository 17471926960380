import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';

import { College } from '../models/college';


@Injectable({
  providedIn: 'root'
})
export class NlocateService {
  private collegeCollection: AngularFirestoreCollection<College>;
  colleges: Observable<College[]>;

  constructor(private afs: AngularFirestore) { 
    this.collegeCollection = afs.collection<College>('colleges');
    this.colleges = this.collegeCollection.valueChanges();
  }

  getColleges(): Observable<College[]> {
    return this.colleges;
  }

  addCollege(college: College) {
    // Persist a document id
    const id = this.afs.createId();
    this.collegeCollection.doc(id).set(college);
  }
}
