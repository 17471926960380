import { Component, OnInit } from '@angular/core';
import { College } from '../../models/college';
import { Observable } from 'rxjs';
import { NlocateService } from '../../services/nlocate.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  colleges: Observable<College[]>;
  constructor(nlocateService : NlocateService) { 
    this.colleges = nlocateService.getColleges();
  }

  ngOnInit(): void {
  }

}
