import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable, of } from 'rxjs';
import { User } from 'src/models/user';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<User>;
  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public afs: AngularFirestore,
    private router: Router
  ) { 

    // Get the auth state, then fetch the Firestore user document or return null
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
          // Logged in
          let uid = localStorage.getItem('uid');
        if (uid) {
          return this.afs.doc<User>(`users/${uid}`).valueChanges();
        } else {
          // Logged out
          console.log('not logged in');
          return of(null);
        }
      })
    )
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
        localStorage.setItem("uid", result.user.uid);
        this.updateUserData(result.user);
    }).catch((error) => {
      
        console.log(error)
    })
  }

  async signOut() {
    localStorage.removeItem("uid");
    this.router.navigate(['/']);
  }

  private updateUserData(data) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${data.uid}`);
    const tempuser = { 
      uid: data.uid, 
      name: data.displayName,
      email: data.email, 
      role: '',
      img: data.photoURL
    } 
    
    return userRef.set(tempuser, { merge: true })

  }
}
