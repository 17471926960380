import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { User } from 'src/models/user';
import { AuthService } from 'src/services/auth.service';
import { faUser, faSign } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  currentUser : User;
  faUser = faUser;
  faSign = faSign;
  constructor(public authService: AuthService) { 
  }

  ngOnInit() { 
    this.authService.user.subscribe(
            (userData) => {
              this.currentUser = userData;
            }
          )
  }

}
