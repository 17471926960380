<nav class="navbar navbar-expand navbar-light navbar-bg">
  <a class="sidebar-toggle d-flex">
    <i class="hamburger align-self-center"></i>
  </a>

  <form class="d-none d-sm-inline-block">
    <div class="input-group input-group-navbar">
      <input
        type="text"
        class="form-control"
        placeholder="Search…"
        aria-label="Search"
      />
      <button class="btn" type="button">
        <i class="align-middle" data-feather="search"></i>
      </button>
    </div>
  </form>

  <div class="navbar-collapse collapse">
    <ul class="navbar-nav navbar-align">
      <div *ngIf="currentUser; then authenticated; else guest">
        <!-- template will replace this div -->
      </div>
      <ng-template #guest>
        <li class="nav-item">
          <div class="position-relative">
            <a (click)="authService.GoogleAuth()">
              <img
                src="../../assets/img/icons/btn_google_signin_dark_normal_web.png"
              />
            </a>
          </div>
        </li>
      </ng-template>
      <ng-template #authenticated>
        <li class="nav-item dropdown">
          <a
            class="nav-icon dropdown-toggle"
            href="#"
            id="alertsDropdown"
            data-bs-toggle="dropdown"
          >
            <div class="position-relative">
              <i-feather class="align-middle" name="bell"></i-feather>
              <span class="indicator">4</span>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="alertsDropdown"
          >
            <div class="dropdown-menu-header">4 New Notifications</div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i-feather
                      class="text-danger"
                      name="alert-circle"
                    ></i-feather>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Update completed</div>
                    <div class="text-muted small mt-1">
                      Restart server 12 to complete the update.
                    </div>
                    <div class="text-muted small mt-1">30m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i-feather class="text-warning" name="bell"></i-feather>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Lorem ipsum</div>
                    <div class="text-muted small mt-1">
                      Aliquam ex eros, imperdiet vulputate hendrerit et.
                    </div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i-feather class="text-primary" name="home"></i-feather>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Login from 192.186.1.8</div>
                    <div class="text-muted small mt-1">5h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i-feather
                      class="text-success"
                      name="user-plus"
                    ></i-feather>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">New connection</div>
                    <div class="text-muted small mt-1">
                      Christina accepted your request.
                    </div>
                    <div class="text-muted small mt-1">14h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all notifications</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-icon dropdown-toggle"
            href="#"
            id="messagesDropdown"
            data-bs-toggle="dropdown"
          >
            <div class="position-relative">
              <i-feather class="align-middle" name="message-square"></i-feather>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="messagesDropdown"
          >
            <div class="dropdown-menu-header">
              <div class="position-relative">4 New Messages</div>
            </div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="assets/img/avatars/avatar-5.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Vanessa Tucker"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Vanessa Tucker</div>
                    <div class="text-muted small mt-1">
                      Nam pretium turpis et arcu. Duis arcu tortor.
                    </div>
                    <div class="text-muted small mt-1">15m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="assets/img/avatars/avatar-2.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="William Harris"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">William Harris</div>
                    <div class="text-muted small mt-1">
                      Curabitur ligula sapien euismod vitae.
                    </div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="assets/img/avatars/avatar-4.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Christina Mason"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">{{ currentUser.name }}</div>
                    <div class="text-muted small mt-1">
                      Pellentesque auctor neque nec urna.
                    </div>
                    <div class="text-muted small mt-1">4h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="assets/img/avatars/avatar-3.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Sharon Lessman"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Sharon Lessman</div>
                    <div class="text-muted small mt-1">
                      Aenean tellus metus, bibendum sed, posuere ac, mattis non.
                    </div>
                    <div class="text-muted small mt-1">5h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all messages</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-icon dropdown-toggle d-inline-block d-sm-none"
            href="#"
            data-bs-toggle="dropdown"
          >
            <i-feather class="align-middle" name="settings"></i-feather>
          </a>

          <a
            class="nav-link dropdown-toggle d-none d-sm-inline-block"
            href="#"
            data-bs-toggle="dropdown"
          >
            <img
              src="{{ currentUser.img }}"
              class="avatar img-fluid rounded me-1"
              alt="Charles Hall"
            />
            <span class="text-dark">{{ currentUser.name }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" href="/profile"
              ><i-feather class="align-middle me-1" name="user"></i-feather>
              Profile</a
            >
            <a class="dropdown-item" href="#"
              ><i-feather
                class="align-middle me-1"
                name="pie-chart"
              ></i-feather>
              Analytics</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="pages-settings.html"
              ><i-feather class="align-middle me-1" name="settings"></i-feather>
              Settings & Privacy</a
            >
            <a class="dropdown-item" href="#"
              ><i-feather
                class="align-middle me-1"
                name="help-circle"
              ></i-feather>
              Help Center</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="/login">Log out</a>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
