import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { Camera, Heart, Github, Bell, User, AlertCircle, Home, UserPlus, MessageSquare, Settings, PieChart, HelpCircle } from 'angular-feather/icons';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Select some icons (use an object, not an array)
const icons = {
  Camera,
  Heart,
  Github,
  Bell,
  User,
  AlertCircle,
  Home,
  UserPlus,
  MessageSquare,
  Settings,
  PieChart,
  HelpCircle
};

@NgModule({
  imports: [
    FeatherModule.pick(icons),
    FontAwesomeModule
  ],
  exports: [
    FeatherModule,
    FontAwesomeModule
  ]
})
export class IconsModule { }