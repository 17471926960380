import { Component, OnInit } from '@angular/core';
import { User } from 'src/models/user';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  currentUser : User;
  constructor(public authService: AuthService) { 
  }

  ngOnInit() { 
    this.authService.user.subscribe(
            (userData) => {
              this.currentUser = userData;
            }
          )
  }
}
