import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email : FormControl;
  role : FormControl;
  user: User;
  formData: FormGroup;
  submitted = false;


   constructor(private authService : AuthService, private router : Router) {
    }

   ngOnInit() {
      this.formData = new FormGroup({
        email: new FormControl("admin"),
        role: new FormControl("admin"),
      });
   }

   onClickSubmit(data: any) {
      this.submitted = true;
      this.user.email = data.email;
      console.log(data);
      //this. = data.password;
      //this.user

      console.log("Login page: " + this.user.email);

      // this.authService.login(this.user)
      //    .subscribe( data => { 
      //       console.log("Is Login Success: " + data); 
      
      //      if(data) this.router.navigate(['/']); 
      // });
   }

}
