import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/models/user';
import { AuthService } from 'src/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'nlocate.org';
    currentUser: User;
 
    constructor(firestore: AngularFirestore, public authService: AuthService) {

      
    };

    ngOnInit() { 
      this.authService.user.subscribe(
              (userData) => {
                this.currentUser = userData;
              }
            )
    }
}
