<div class="wrapper">
  <app-side-bar></app-side-bar>
  <div class="main">
    <app-top-bar></app-top-bar>
    <main class="content">
      <!--Body Content -->
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </div>
</div>
